import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      $_attribute: "attributes",
      $_attribute_values: "attributeValues",
    }),
    $_attributeData() {
      return this.$_attribute;
    },
    $_attributeValuesData() {
      return this.$_attribute_values;
    },
  },
};
