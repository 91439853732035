<script>
/**
 * Sales Analytics component
 */
export default {
    props: {
        artworkServiceCount: Array,
        artworkServiceLabels: Array,
        artworkServiceData:Object
    },
    data() {
        return {
            // series: [42, 26, 15],
            chartOptions: {
                chart: {
                    height: 230,
                    type: "donut"
                },
                labels: this.artworkServiceLabels,
                plotOptions: {
                    pie: {
                        donut: {
                            size: "65%"
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                colors: ["#5664d2", "#1cbb8c", "#eeb902", "#5664d2", "#1cbb8c", "#eeb902"]
            }
        };
    }
};
</script>

<template>
    <b-row>
        <b-col cols="12">
            <div class="card">
                <div class="card-body">
                    <!-- <div class="float-right">
                        <select class="custom-select custom-select-sm">
                            <option selected>Apr</option>
                            <option value="1">Mar</option>
                            <option value="2">Feb</option>
                            <option value="3">Jan</option>
                        </select>
                    </div>
                    <h4 class="card-title mb-4">Sales Analytics</h4> -->

                    <div id="donut-chart" class="apex-charts"></div>
                    <apexchart class="apex-charts" height="230" dir="ltr" :series="artworkServiceCount"
                        :options="chartOptions">
                    </apexchart>
                </div>
            </div>
        </b-col>
        <b-col cols="12">
            <b-card>
                <div class="table-responsive mt-4">
                    <table class="table table-hover mb-0 table-centered table-nowrap">
                        <tbody>
                            <tr>
                                <th>Service Amount</th>
                                <th>Items</th>
                            </tr>
                            <tr v-for="(item, index) in artworkServiceData" :key="index">
                                <td>
                                    <p class="text-muted mb-0">&#163;{{ item.design_service_price }}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-0">{{ item.count }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>