<script>
import { MEDIA } from "@/api/global.env.js"
import Swal from "sweetalert2";
export default {
  data() {
    return {
      URL: MEDIA,
      orderData: {},
      artworkStatus: false,
      artworkSidebar: false,
      isEdit: false,
      formTitle: "",
      id: null,
      selectedStatus: "",
      attribute_id: null,
      currentPage: 1,
      selectedProduct: null,
      supplierList: [],
      supplier: "",
      data: {
        name: "",
        description: "",
        attribute: "",
      },
      supplierArtworkModel: false,
      message: "",
      FILE: null,
      acceptedArtwork: null,
    };
  },
  mounted() {
    this.$store.getters.client.patch(
      `/order/create-order/${this.$route.params.id}/`,
      { new_order: false }
    );
    this.getOrder();
  },
  methods: {
    sendProof() {
      let data = {
        title: "test",
        discription: "dfkgnfd jdfgjkdkn",
      };
      this.$store.getters.client
        .post(`/order/order-activity/`, data)
        .then(() => { });
    },
    uploadSupplierArtwork(item) {
      this.acceptedArtwork = item
      this.supplierArtworkModel = true;
    },
    uploadSupplierFile() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("supplier_attachments", this.FILE, this.FILE.name);
        this.$store.getters.client
          .patch(`order/artwork-proofing/${this.acceptedArtwork.id}/`, formData)
          .then(() => {
            this.FILE = null;
            this.supplierArtworkModel = false
            this.getOrder();
          });
      }
    },
    sendProofMail() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let emailData = []
      this.orderData.order_items.forEach((element) => {
        if (element.currentArtwork?.status !== 'accepted' || element.currentArtwork?.status !== 'received') {
          emailData.push({ artwork_proofing_id: element.currentArtwork?.id })
        }
      })
      if (emailData.length === 0) {
        alert('All artworks are approved or not uploaded')
      } else {
        this.$store.getters.client.post(
          `/order/send-artwork-email/`, emailData
        ).then(() => { loader.hide() }).catch((error) => { console.log(error); });
      }
      loader.hide()
    },
    async confirmSendProofMail(){
      await Swal.fire({
        title: "Send Mail",
        text: "Do you want to send artwork confermation mail?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "yes, send!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendProofMail()
        }
      });
    },
    getOrder() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.order_id = this.$route.params.id;
      this.$store.getters.client
        .get(`/order/get-order-details/${this.order_id}`)
        .then((response) => {
          this.orderData = response.data.results;
          this.selectedProduct = this.orderData.order_items[0];
          this.orderData.order_items.forEach((x) => {
            x.design_service_price = x.product_data?.product_design_service?.find((service) => { return service.price == Number(x.design_service_price) });
            x.currentArtwork = x.artwork_proofing ? x.artwork_proofing[0] : null
          })

          console.log(this.orderData)
          loader.hide();
        });
    },
    updateStatus(item) {
      this.$store.getters.client
        .patch(`/order/order-items/${item}/`, {
          status: this.selectedProduct.status,
        })
        .then(() => {
          this.artworkStatus = false;
          this.getOrder();
        });
    },
    async uploadUserArtwork(event, item) {
      console.log(item, event)
      const FILE = await event.target.files[0];
      if (FILE.size > 104857600) { // 104857600 bytes = 100MB
        alert("File size exceeds the limit of 100MB. Please select a smaller file.");
        return;
      }
      if (FILE) {
        let formData = new FormData();
        formData.append("user", this.orderData.user.id);
        formData.append("order_item_id", item.id);
        formData.append("artwork", FILE);
        try {
          const response = await this.$store.getters.client.post(`/order/user-artworks/`, formData)
          const artwork = response.data;
          console.log(artwork)
        } catch (error) {
          // this.$toast.error(error);
          alert(error)
        }
        finally {
          this.getOrder()
          // this.loading = false
        }
      }
    },
    sendLink(value) {
      Swal.fire({
        title: "Send Email?",
        text: "Do you want to send email for upload artwork",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        console.log(result, value)
        if (result.isConfirmed) {
          this.$store.getters.client.get(`/order/order-artwork/${value.order_id}`)
          // this.updateQuote(value)
        }
      });
    },
    uploadArtwork(item) {
      console.log(item);
      let formData = new FormData();
      if (this.FILE) {
        formData.append("attachments", this.FILE, this.FILE.name);
      }
      formData.append("order_id", this.$route.params.id);
      formData.append("order_item_id", item.id);
      this.$store.getters.client
        .post(`order/artwork-proofing/`, formData)
        .then(() => {
          this.FILE = null;
          this.getOrder();
          this.artworkSidebar = false;
        });
    },
    uploadImage(event) {
      this.FILE = event.target.files[0];
    },
  },
  beforeMount() {
    window.addEventListener("mousewheel", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("mousewheel", this.handleScroll);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="mb-3 b-inline">
        <i class="fas fa-arrow-left"></i>
        <span class="mr-2 ml-3">#{{ orderData.id }}</span>
        <a href="#" class="badge badge-success">PAID</a>
        <div class="float-right">
          <a class="mr-2">Refund</a>
          <a class="mr-2">Return</a>
          <Span class="mr-2">Edit</Span>
          <span>More actions</span>
          <i class="fas fa-arrow-right ml-2"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-button variant="success" class="float-right mb-3" @click="confirmSendProofMail">Send Proof Mail</b-button>
          <div class="table-responsive">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="bg-light">
                <tr>
                  <th style="width: 120px">Product</th>
                  <th>Product Desc</th>
                  <th>User Artwork</th>
                  <th>Service</th>
                  <th>Status</th>
                  <th>Manage Artwork</th>
                  <th>Delivery</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orderData.order_items" :key="item.id">
                  <td>
                    <img v-if="item.product_data.product_images" :src="item.product_data.product_images[0].image"
                      alt="product-img" title="product-img" class="avatar-md" />
                    <img v-if="item.quotation_product?.image" :src="item.quotation_product?.image" alt="product-img"
                      title="product-img" class="avatar-md" />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <a href="ecommerce-product-detail.html" class="text-dark">{{ item.product_data.name ||
                        item.quotation_product.name }}</a>
                    </h5>
                    <div v-for="varient in item.variant" :key="varient.id">
                      <div>{{ varient.attribute.name }} : <b>{{ varient.attribute_value.name }}</b></div>
                    </div>
                    <div v-if="item.quotation_product?.description" style="white-space: normal;">{{
                      item.quotation_product?.description }}</div>
                  </td>
                  <td>
                    <b-button-group vertical size="sm">
                      <b-button variant="success" v-if="item.user_artwork.artwork">
                        <a class="d-block text-white" :href="item.user_artwork.artwork" target="_blank">Artwork</a>
                      </b-button>
                      <b-button type="button" variant="warning" @change="uploadUserArtwork($event, item)">
                        <input type="file" :id="'artwork' + item.id" hidden />
                        <label :for="'artwork' + item.id">Upload</label>
                      </b-button>
                      <b-button variant="primary" @click="sendLink(item)">Send Link</b-button>
                    </b-button-group>
                  </td>
                  <!-- <td>&#163;{{ Number(item?.design_service_price?.price).toFixed(2) }} <br> {{
                    item?.design_service_price?.name }}</td> -->
                    <td>&#163;{{ item.design_service_price || '0.00'}}</td>
                  <!-- <td>
                    <div v-if="item.artwork_proofing.length > 0">
                      <b-badge v-if="item.currentArtwork?.status == 'received'" variant="info">PROOF SENT</b-badge>
                      <b-badge v-if="item.currentArtwork?.status == 'accepted'" variant="success">APPROVED</b-badge>
                      <b-badge v-if="item.currentArtwork?.status == 'rejected'" variant="danger">REjECTED</b-badge>
                    </div>
                    <div v-else><b-badge variant="warning">PENDING</b-badge></div>
                  </td> -->
                  <td>

                    <b-button-group size="sm">
                      <b-button variant="primary" size="sm" @click="artworkSidebar = true; selectedProduct = item;">
                        Send Proof
                      </b-button>
                      <b-button size="sm" variant="success" type="button"
                        @click="uploadSupplierArtwork(item.currentArtwork ? item.currentArtwork : null)">
                        {{ item.currentArtwork?.supplier_attachments !== '' ? 'Update HD File' : 'Upload HD File' }}
                      </b-button> <b-button v-if="item.currentArtwork?.supplier_attachments !== ''" variant="warning"
                        size="sm"><a :href="URL + item.currentArtwork?.supplier_attachments" target="_blank"
                          class="text-white"><i class="ri-download-cloud-2-fill"></i></a></b-button>
                    </b-button-group><br>
                    <span>{{ item.currentArtwork?.updated_at | moment("DD MMM, YYYY | HH:mm") }}</span>
                  </td>
                  <td v-if="item.delivery_date.length > 0">
                    {{ item.delivery_date[item.delivery_date.length - 1].delivery_meathod }} <br>
                    {{ item.delivery_date[item.delivery_date.length - 1].delivery_date | moment("DD MMM, YYYY") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar v-model="artworkSidebar" title="Manage Proof" right backdrop shadow
      header-class="d-flex justify-content-between flex-row-reverse">
      <template #footer="{ hide }">
        <div class="px-3 py-2">
          <div class="border-img">
            <label for="">Image</label> <br />
            <input type="file" v-on:change="uploadImage($event)" />
          </div>
          <b-button variant="primary" @click="uploadArtwork(selectedProduct);
          hide;" class="w-100 text-uppercase mt-3">Send Artwork</b-button>
        </div>
      </template>
      <div class="px-3 py-2" v-if=" selectedProduct ">
        <p>
          Artwork of
          <span class="text-success">{{ selectedProduct.product_data.name ||
            selectedProduct.quotation_product.name}}</span>
        </p>
        <div>
          <!-- {{selectedProduct}} -->
          <ul class="list-unstyled activity-wid mb-5">
            <li class="activity-list" v-for=" item  in  selectedProduct.artwork_proofing " :key=" item.id ">
              <div class="activity-icon avatar-xs">
                <span class=" avatar-title bg-soft-primary text-primary rounded-circle"></span>
              </div>
              <div>
                <p class="mb-2">{{ item.attachments }}</p>
                <h5 class="font-size-13 mb-0">
                  <div v-if=" item.attachments ">
                    <a :href=" URL + item.attachments " target="_blank"> Download Attachment</a>
                    <span>
                      <b-badge v-if=" item.status == 'received' " variant="warning">PENDING</b-badge>
                      <b-badge v-if=" item.status == 'accepted' " variant="success">APPROVED</b-badge>
                      <b-badge v-if=" item.status == 'rejected' " variant="danger">REjECTED</b-badge>
                    </span>
                  </div>
                  <!-- {{ item.attachments }} -->
                </h5>
                <small class="text-muted">{{item.created_at | moment("DD MMM, YYYY | HH: mm")}}</small>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-sidebar>

    <b-sidebar v-model=" artworkStatus " title="Manage Status" right backdrop shadow
      header-class="d-flex justify-content-between flex-row-reverse">
      <template #footer=" { hide } ">
        <div class="px-3 py-2">
          <b-button variant="primary" @click="
            updateStatus(selectedProduct.id);
            hide;
          " class="w-100 text-uppercase">Update status</b-button>
        </div>
      </template>
      <div class="px-3 py-2" v-if=" selectedProduct ">
        <div class="d-flex mb-3">
          <div class="text-uppercase">
            <b>{{ selectedProduct.product_data.name }}</b>
          </div>
        </div>
        <div class="mb-0" v-html=" selectedProduct.product_data.short_description "></div>
        <hr />
        <label for="">Select Status</label>
        <b-form-select class="form-control" placeholder="Select status" v-model=" selectedProduct.status ">
          <b-form-select-option value="" disabled>Please select an option</b-form-select-option>
          <b-form-select-option value="in_review">In review</b-form-select-option>
          <b-form-select-option value="accepted">Accepted</b-form-select-option>
          <b-form-select-option value="rejected">Reject</b-form-select-option>
        </b-form-select>
      </div>
    </b-sidebar>
    <b-modal v-model=" supplierArtworkModel " hide-footer title="Send artwork to supplier">
      <div class="px-3 py-2">
        <div class="border-img">
          <label for="">FILE</label> <br />
          <input type="file" v-on:change="uploadImage($event)" />
        </div>
        <b-button variant="primary" @click="uploadSupplierFile()" class="w-100 text-uppercase mt-3">Save
          Artwork</b-button>
      </div>
    </b-modal>
    <b-row class="col-lg-12">
      <b-col cols="4" v-for=" item  in  orderData.order_items " :key=" item.id ">
        <b-card :title=" `Artwork of ${item.product_data.name || item.quotation_product.name}` ">
          <div>
            <ul class="list-unstyled activity-wid mb-5 w-100 ">
              <li class="activity-list" v-for=" artwork  in  item.artwork_proofing " :key=" artwork.id ">
                <div class="activity-icon avatar-xs">
                  <span class=" avatar-title bg-soft-primary text-primary rounded-circle"></span>
                </div>
                <div>
                  <p class="mb-2">{{ artwork.attachments }}</p>
                  <h5 class="font-size-13 mb-0">
                    <div v-if=" artwork.attachments ">
                      <a :href=" URL + artwork.attachments " target="_blank">
                        Download Attachment
                      </a>
                      <span>
                        <b-badge v-if=" artwork.status == 'received' " variant="warning">PENDING</b-badge>
                        <b-badge v-if=" artwork.status == 'accepted' " variant="success">APPROVED</b-badge>
                        <b-badge v-if=" artwork.status == 'rejected' " variant="danger">REjECTED</b-badge>
                      </span>
                    </div>
                    <!-- {{ artwork.attachments }} -->
                  </h5>
                  <div class="bg-light mt-2">{{ artwork.comment }}</div>
                  <small class="text-muted">Proof sent {{artwork.created_at | moment("DD MMM, YYYY | HH:mm")}}</small><br>
                  <small class="text-muted">Last updated {{artwork.updated_at | moment("DD MMM, YYYY | HH: mm")}}</small>
                </div>
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.link {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.link:hover {
  background: #ddd;
}
</style>
