export default {
    updateAttributeVariants: (state, paylaod) => {
        state.attributeVariantsData = paylaod;
    },
    setallAttribute: (state, payload) => {
        state.allAttribute = payload
    },
    set_atribute_values: (state) => {
        state.atributeValues = []
        state.allAttribute.forEach((x, i) => {
            state.atributeValues.push({
                attribute_values: []
            })
            state.allAttribute[i].attribute_values.forEach(() => {
                state.atributeValues[i].attribute_values = []
            })
        });
    },
    updateAttributeValue: (state, payload) => {
        state.atributeValues[payload.index].attribute_values = payload.data;
    },
    checkCreateStateus: (state, paylaod) => {
        state.checkCreateStateus = paylaod;
    },
    set_attributePreivew: (state, payload) => {
        state.attributePreivew = payload;
    },
    resetAttributeVariants: state => {
        state.attributeVariantsData = {};
    },
    totally_empty: (state) => {
        state.attributeVariantsData = {}
        state.allAttribute = []
        state.atributeValues = []
        state.checkCreateStateus = false
        state.attributePreivew = []
    }
};