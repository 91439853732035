<script>
export default {
  name: 'Studio',
  data() {
    return {
      orderData: [],
      search: '',
      status: '',
      order_items__status: '',
      start_date: '',
      end_date: '',
      pagination: {
        totalResults: 1,
        currentPage: 1,
        totalPages: 1,
        perPage: 1
      },
      fields: [
        { key: "id", sortable: true, label: "Order" },

        { key: "created_at", sortable: true, label: "Date" },
        { key: "user", sortable: true, label: "Customer" },
        { key: "total_items", label: "Items" },
        { key: "shipping_method", label: "Delivery Methods" },
        { key: "artwork_status", label: "Artwork Status" },
      ],
    };
  },
  mounted() {
    this.getOrderData(1);
  },
  methods: {
    myRowClickHandler(event) {
      this.$router.push({ name: "ManageStudio", params: { id: event.id } });
    },
    goToCreateOrders() {
      this.$router.push({ name: "CreateOrders" });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    getOrderData(value) {
      this.$store.getters.client
        .get(`/order/create-order/?page=${value || 1}${this.search ? '&search=' + this.search : ''}${this.status ? '&status=' + this.status : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}${this.order_items__status ? '&order_items__status=' + this.order_items__status : ''}`)
        .then((response) => {
          this.orderData = response.data.results;
          this.orderData.forEach(ele => {
            ele.order_items.forEach((x) => {
              x.currentArtwork = x.artwork_proofing_item ? x.artwork_proofing_item[x.artwork_proofing_item.length - 1] : null
              console.log(ele);
            })
          })
          this.pagination.totalResults = response.data.total_count,
            this.pagination.currentPage = response.data.current_page,
            this.pagination.totalPages = response.data.total_pages,
            this.pagination.perPage = response.data.per_page
        }).catch((error) => {
          alert('something went wrong', error)
        });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">All</a>
              </template>

              <div class="row">
                <div class="col-lg-4 pt-3 pr-0 col">
                  <b-form-group>
                    <b-form-input @input="getOrderData(1)" v-model="search" size="sm" type="search" placeholder="Search">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="pt-3 pr-0 col">
                  <b-form-group>
                    <b-form-select @change="getOrderData(1), order_items__status = ''" v-model="status" size="sm" placeholder="status">
                      <b-form-select-option value="">Select status</b-form-select-option>
                      <b-form-select-option value="pending">PENDING</b-form-select-option>
                      <b-form-select-option value="in_production">IN PRODUCTION</b-form-select-option>
                      <b-form-select-option value="delayed">DELAYED</b-form-select-option>
                      <b-form-select-option value="dispatched">DISPATCHED</b-form-select-option>
                      <b-form-select-option value="delivered">DELIVERED</b-form-select-option>
                      <b-form-select-option value="cancelled">CANCELLED</b-form-select-option>
                      <b-form-select-option value="refunded">REFUNDED</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="pt-3 pr-0 col">
                  <b-form-group>
                    <b-form-select @change="getOrderData(1), status = ''" v-model="order_items__status" size="sm" placeholder="status">
                      <b-form-select-option value="">Select status</b-form-select-option>
                      <b-form-select-option value="pending">PENDING</b-form-select-option>
                      <b-form-select-option value="proof_sent">PROOF SENT</b-form-select-option>
                      <b-form-select-option value="rejected">ARTWORK REJECTED</b-form-select-option>
                      <b-form-select-option value="accepted">ARTWORK APPROVED</b-form-select-option>
                      <b-form-select-option value="in_production">IN PRODUCTION</b-form-select-option>
                      <b-form-select-option value="delayed">DELAYED</b-form-select-option>
                      <b-form-select-option value="dispatched">DISPATCHED</b-form-select-option>
                      <b-form-select-option value="delivered">DELIVERED</b-form-select-option>
                      <b-form-select-option value="cancelled">CANCELLED</b-form-select-option>
                      <b-form-select-option value="refunded">REFUNDED</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="pt-3 pl-0 col">
                  <b-form-group>
                    <b-form-datepicker size="sm" today-button reset-button close-button id="valid_from"
                      v-model="start_date" :max="new Date()" @input="getOrderData(1)"></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="pt-3 pl-0 col">
                  <b-form-group>
                    <b-form-datepicker size="sm" today-button reset-button close-button id="end_date"
                      v-model="end_date" :max="new Date()" @input="getOrderData(1)"></b-form-datepicker>
                  </b-form-group>
                </div>
              </div>
              <!-- End search -->

              <div class="table-responsive">
                <b-table hover class="table-centered" :items="orderData" :fields="fields" responsive="sm" @row-clicked="myRowClickHandler">
                  <template v-slot:cell(id)="row">
                    <span>#{{ row.item.number }} <b-badge v-if="row.item.new_order == true"
                        variant="success">New</b-badge></span>
                  </template>
                  <template v-slot:cell(created_at)="row">
                    <span>{{ row.item.created_at | moment("DD MMM, YYYY") }}</span>
                  </template>
                  <template v-slot:cell(price_amount)="row">
                    <span>£{{ row.item.price_amount }}</span>
                  </template>

                  <template v-slot:cell(payment_status)>
                    <a href="#" class="badge badge-success">PAID</a>
                  </template>
                  <template v-slot:cell(total_items)="row">
                    {{ row.item.order_items.length }} items
                  </template>
                  <template v-slot:cell(artwork_status)="row">
                    <div v-for="(item, index) in row.item.order_items" :key="index">
                      <div v-if="item.currentArtwork">
                        <b-badge v-if="item.currentArtwork?.status === 'received'" variant="info">PROOF SENT</b-badge>
                        <b-badge v-if="item.currentArtwork?.status === 'accepted'" variant="success">APPROVED</b-badge>
                        <b-badge v-if="item.currentArtwork?.status === 'rejected'" variant="danger">REJECTED</b-badge>
                      </div>
                      <div v-else><b-badge variant="warning">PENDING</b-badge></div>
                      <!-- {{ item.currentArtwork }} -->
                    </div>
                  </template>
                  <template v-slot:cell(shipping_method)="row">
                    <template v-if="row.item.order_items">
                      <div v-for="(product, i) in row.item.order_items" :key="i">
                        <span v-if="product.delivery_date.length > 0">
                          {{ product.delivery_date[product.delivery_date.length - 1].delivery_date | moment("DD MMM, YYYY")}} |
                          <span>{{ product.delivery_date[product.delivery_date.length - 1].delivery_date | moment("from",
                            "now", true) }}</span>
                        </span>
                      </div>
                    </template>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="pagination.currentPage" :total-rows="pagination.totalResults"
                        :per-page="pagination.perPage" @change="getOrderData"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
