import mutations from "./attributes-and-varients/mutation";
import actions from "./attributes-and-varients/actions"
export default {
    state: {
        attributeVariantsData: {},
        allAttribute: [],
        atributeValues: [],
        checkCreateStateus: false,
        attributePreivew: []
    },
    mutations,
    actions
};
