<script>
// import Stat from "./widget";
import ArtworkServices from "./artworkService.vue";
export default {
  components: { ArtworkServices },
  data() {
    return {
      title: "Dashboard",
      analyticData: null,
      serviceCounts:[],
      serviceLabels:[],
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getAnalytics()
  },
  methods: {
    async getAnalytics() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const result = await this.$store.getters.client.get(`/order/studio-dashboard/`)
        console.log(result)
        this.analyticData = result.data
         this.serviceLabels = this.analyticData.artwork_service.map((label) => label.design_service_price);
         this.serviceCounts = this.analyticData.artwork_service.map((count) => count.count);
      } catch (error) {
        console.error(error)
      } finally {
        loader.hide()
      }
    }
  }
};
</script>

<template>
  <b-row>
    <b-col cols="4">
      <b-card title="New Jobs">
        <h1 class="text-center py-4">{{ analyticData.new_artwork }}</h1>
      </b-card>
    </b-col>
    <b-col cols="4">
      <b-card title="Pending Approval">
        <h1 class="text-center py-4">{{ analyticData.pending_artwork }}</h1>
      </b-card>
    </b-col>
    <b-col cols="4">
      <b-card title="Completed Jobs">
        <h1 class="text-center py-4">NA</h1>
      </b-card>
    </b-col>

    <b-col cols="6">
      <b-card title="Artwork Services">
        <ArtworkServices :artworkServiceCount="serviceCounts" :artworkServiceLabels="serviceLabels" :artworkServiceData="analyticData.artwork_service"/>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Popular products">
        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0 table-centered table-nowrap">
            <tbody>
              <tr v-for="(item, index) in analyticData.popular_product" :key="index">
                <td>
                  <p class="text-muted mb-0">{{ item.product__name }}</p>
                </td>
                <td>
                  <p class="text-muted mb-0">{{ item.count }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>

    </b-col>
    <!-- <div class="col-xl-12">
      <Stat :analytic="analyticData" />
    </div> -->
  </b-row>
</template>
